import { io } from "socket.io-client";

const options = {
    "force new connection": true,
    reconnectionAttempts: "Infinity",
    timeout: 100000,
    transports: ["websocket"]
}

// const socket = io('http://localhost:3001', options);
const socket = io('https://calls.justenglish.by', options);

export default socket;