import {useParams} from "react-router";
import useWebRTC, {LOCAL_VIDEO} from "../../hooks/useWebRTC";

export default function Room() {
    const {id: roomID} = useParams();
    const {clients, provideMediaRef} = useWebRTC(roomID);

    console.log("c: ", clients);

    console.log("r: ", roomID);
    return (
        <div>
            {clients.map((clientID) => {
                return (
                    <div key={clientID}>
                        <video
                            ref={instance => {
                                provideMediaRef(clientID, instance);
                            }}
                            autoPlay={true}
                            playsInline={true}
                            muted={clientID === LOCAL_VIDEO}
                            />
                    </div>
                );
            })}
        </div>
    );
}