import socket from "../../socket";
import {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {v4} from "uuid";
export default function Main() {
    const navigate = useNavigate();
    const [rooms, updateRooms] = useState([]);
    const rootNode = useRef();

    useEffect(() => {
        socket.on('share-rooms', ({rooms = []} = {}) => {
            console.log("rooms: ", rooms, rootNode)
            if (rootNode.current) {
                updateRooms(rooms);
            }
        });
    }, []);

    return (
        <div ref={rootNode}>
            <h1>Rooms</h1>
            <button onClick={() => {
                navigate(`/room/${v4()}`);
            }}>Create new room</button>
            <hr />
            <ul>
                {
                    rooms.map(roomID => (
                        <li key={roomID}>
                            {roomID}
                            <button onClick={() => {
                                navigate(`/room/${roomID}`);
                            }}>Join room</button>
                        </li>
                    ))
                }
            </ul>
        </div>
    );
}